<template>
  <div>
    <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-cog"></i> Settings</h2>
    <div class="col-12" style="background-color: white; padding: 2%">
      <div class="row">
        <b-tabs class="col-12" align="center" ref="clk_mon_tabs" pills>
          <b-tab title="System Information" active @click="tabbed">
            <system :item="item"
          /></b-tab>
          <b-tab title="Crawler Settings" @click="tabbed"> <crawler :item="item" /></b-tab>
            <b-tab title="Pitcher Settings" @click="tabbed"><pitchers :item="item" :colors="colors" /></b-tab>
<!--          <b-tab title="Provider Settings" @click="tabbed">-->
<!--            <providers :item="item" :providers="providers" :colors="colors" />-->
<!--          </b-tab>-->
<!--            <b-tab title="Proxy Settings" @click="tabbed">-->
<!--                <proxy :providers="providers" />-->
<!--            </b-tab>-->
          <b-tab title="SMTP Settings" @click="tabbed"> <smtp :item="item" /></b-tab>
          <b-tab title="Addition Settings" @click="tabbed"> <addition :item="item" /></b-tab>
          <b-tab title="Click Monitoring" @click="tabbed"> <click_monitoring @addClickSave="save" :item="item" v-on:forecastUpdate="item = $store.state.settings" v-on:filter="load($event)" /></b-tab>
          <b-tab title="Chat" @click="tabbed"> <chat v-if="chat" :item="item" /></b-tab>
          <b-tab title="Devices" @click="tabbed"> <device :item="item" v-on:addDevices="addDevices" v-on:removeDevices="removeDevices" /></b-tab>
            <b-tab title="System Incident Widget" @click="tabbed"><system_incidents :user="user"/> </b-tab>
          <b-tab title="Email Verifier" @click="tabbed"> <ev :item="item" /></b-tab>
          <b-tab title="Automation" @click="tabbed"> <automation v-if="automation" :item="item" /></b-tab>
          <b-tab title="Social" @click="tabbed"> <social :item="item" /></b-tab>
            <b-tab title="Inbox Enhanced" @click="tabbed"> <inboxEhanced :user ="user" :item="item"/></b-tab>
        </b-tabs>
      </div>
      <div class="row justify-content-center" v-if="showButton">
        <div class="form-group">
<!--          <button v-if="clkMon" type="button" class="btn btn-primary btn-lg" @click.prevent="saveClkMon">Update Settings</button>-->
          <button v-if="!chat && !proxy" type="button" class="btn btn-primary btn-lg" @click.prevent="save">Update Settings</button>
        </div>
      </div>
    </div>
    <div class="row editor">
      <div class="col-12">
        <form v-on:submit.prevent="save">
          <div class="row">
            <div class="col-12 col-md-7">
              <!-- <div class="general">
                <h4>System Information</h4>
                <p>Update the core system settings</p>

                <div class="form-group">
                  <label>System Name</label>
                  <input
                    type="text"
                    v-model="item.system"
                    class="form-control"
                    value="Voip Portal"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>System Copyright</label>
                  <input
                    type="text"
                    v-model="item.copyright"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>System Email</label>
                  <input
                    type="text"
                    v-model="item.email"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>System Error Recipients</label>
                  <input
                    type="text"
                    v-model="item.email_errors"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Comments/Feedback Recipients</label>
                  <input
                    type="text"
                    v-model="item.email_feedback"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>System Domain</label>
                  <input
                    type="text"
                    v-model="item.domain"
                    class="form-control"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Company</label>
                  <input
                    type="text"
                    v-model="item.company"
                    class="form-control"
                    value=""
                    required
                  />
                </div>
              </div> -->
              <!-- <div class="general">
                <h4>Proxy Providers</h4>
                <p>Enable proxy providers</p>

                <div
                  class="form-check mb-3"
                  v-for="(provider, index) in providers"
                  :key="index"
                >
                  <div class="row">
                    <div class="col-12 col-lg-2 col-md-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="email"
                        v-model="item.providers"
                        :value="provider"
                      />
                      <label class="form-check-label" for="email">{{
                        provider.name
                      }}</label>
                    </div>
                    <div class="col-12 col-lg-2 col-md-3">
                      <select v-model="provider.color" class="form-control">
                        <option value="">None</option>
                        <option
                          v-for="(color, cindex) in colors"
                          :key="cindex"
                          :value="color.code"
                        >
                          {{ color.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- <div class="col-12 col-md-5">
              <div>
                <h4>Additional Settings</h4>
                <p>The additional system settings</p>

                <div class="form-group">
                  <label>Maintenance Mode</label>
                  <select v-model="item.maintenance" class="form-control">
                    <option value="0">Disabled</option>
                    <option value="1">Enabled</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Maintenance Title</label>
                  <input
                    type="text"
                    v-model="item.maintenance_title"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Maintenance Message</label>
                  <textarea
                    type="text"
                    v-model="item.maintenance_msg"
                    class="form-control"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label
                    >Queue Maintenance for Active Campaign(s) Interval
                    <small>(in minutes)</small></label
                  >
                  <input
                    type="number"
                    v-model="item.maintenance_active_interval"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label
                    >Queue Maintenance for Pending Campaign(s) Interval
                    <small>(in minutes)</small></label
                  >
                  <input
                    type="number"
                    v-model="item.maintenance_pending_interval"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Max TOS <small>in seconds</small></label>
                  <b-form-input
                    v-model="item.link_level_max_time"
                    type="text"
                    size="sm"
                    autocomplete="off"
                    style="height: 37px"
                  ></b-form-input>
                </div>
              </div>

              <div>
                <h4>SMTP Settings</h4>
                <p>The System's email server settings</p>

                <div class="form-group">
                  <label>Secure</label>
                  <select v-model="item.smtp_secure" class="form-control">
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Host</label>
                  <input
                    type="text"
                    v-model="item.smtp_host"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Port</label>
                  <input
                    type="number"
                    v-model="item.smtp_port"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    v-model="item.smtp_user"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    v-model="item.smtp_password"
                    class="form-control"
                  />
                </div>
              </div>

              <div>
                <h4>Crawler Limiter</h4>
                <p>Set a min and max target for the limiter.</p>

                <div class="form-group">
                  <label>Min Target</label>
                  <input
                    type="number"
                    v-model="item.target_min"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Max Target</label>
                  <input
                    type="number"
                    v-model="item.target_max"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Target Percentage</label>
                  <input
                    type="number"
                    v-model="item.target_percentage"
                    step=".0001"
                    class="form-control"
                  />
                </div>
              </div>

              <div>
                <h4>Queue Configuration</h4>
                <p>Configure the queue for the best worker performance..</p>

                <div class="form-group">
                  <label>Service Percentage</label>
                  <input
                    type="number"
                    v-model="item.service_percentage"
                    step=".0001"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Service Interval</label>
                  <input
                    type="number"
                    v-model="item.serviceInterval"
                    step=".0001"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Queue Mode</label>
                  <input
                    type="text"
                    v-model="item.queueMode"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Queue Max</label>
                  <input
                    type="number"
                    v-model="item.queueMax"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Queue Interval</label>
                  <input
                    type="number"
                    v-model="item.queueInterval"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Queue Buster Max</label>
                  <input
                    type="number"
                    v-model="item.queueBusterMax"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label>Queue Percentage</label>
                  <input
                    type="number"
                    v-model="item.queue_percentage"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Queue Check Ip</label>
                  <select v-model="item.queueCheckIp" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="nationwide">Nationwide</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Queue Alternate</label>
                  <select v-model="item.queueAlternate" class="form-control">
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Queue Pitch</label>
                  <select v-model="item.queuePitch" class="form-control">
                    <option value="blitz">Blitz</option>
                    <option value="quality">Quality</option>
                    <option value="speed">Speed</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Queue Homeip</label>
                  <select v-model="item.queueHomeip" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Queue Geosurf</label>
                  <select v-model="item.queueGeosurf" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Queue Netnut</label>
                  <select v-model="item.queueNetnut" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Queue Oxylabs</label>
                  <select v-model="item.queueOxylabs" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>

              <div>
                <h4>Queue API Targeting</h4>
                <p>Set specific overrides for targeted campaigns.</p>

                <div class="form-group">
                  <label>Targeting</label>
                  <select v-model="item.queueTargeting" class="form-control">
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div class="form-group" v-if="item.queueTargeting == 'yes'">
                  <label>Queue Targeting</label>
                  <input
                    type="text"
                    v-model="item.queueTargetingIds"
                    class="form-control"
                  />
                  <div class="form-text text-muted">
                    A comma separated list of tracking ids to target
                  </div>
                </div>

                <div class="form-group" v-if="item.queueTargeting == 'yes'">
                  <label>Queue Targeting Mode</label>
                  <select
                    v-model="item.queueTargetingMode"
                    class="form-control"
                  >
                    <option value="yes">Yes</option>
                    <option value="nationwide">Nationwide</option>
                    <option value="no">No</option>
                  </select>
                  <div class="form-text text-muted">
                    The Queue Check IP mode to apply to the targets
                  </div>
                </div>
              </div>

              <div>
                <h4>Shown Service</h4>
                <p>Set start and stop time for the shown service.</p>

                <div class="form-group">
                  <label
                    >Service Interval <small>(in milliseconds)</small></label
                  >
                  <input
                    type="number"
                    v-model="item.shownInterval"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label
                    >Service Interval (Capped and Shown Report)
                    <small>(in milliseconds)</small></label
                  >
                  <input
                    type="number"
                    v-model="item.cappedAndShownInterval"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>Start time</label>
                  <input
                    type="time"
                    v-model="item.shown_startTime"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label>End time</label>
                  <input
                    type="time"
                    v-model="item.shown_stopTime"
                    class="form-control"
                  />
                </div>
              </div>
            </div> -->
            <!-- <div class="col-12 text-center">
              <div class="form-group">
                <input
                  type="submit"
                  class="btn btn-success btn-lg"
                  value="Update Settings"
                />
              </div>
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import { mapMutations } from "vuex";

import pitchers from "@/components/settings/pitcher";
import system from "@/components/settings/system";
import crawler from "@/components/settings/crawler";
// import providers from "@/components/settings/providers";
import smtp from "@/components/settings/smtp";
import chat from "@/components/settings/chat";
import addition from "@/components/settings/addition";
import device from "@/components/settings/device";
import ev from "@/components/settings/ev";
import click_monitoring from "@/components/settings/click_monitoring";
import system_incidents from "@/components/settings/system_incidents.vue";
import automation from "@/components/settings/automation";
import social from "@/components/settings/social";
import inboxEhanced from "@/components/settings/inboxEnhanced";

// import proxy from "@/components/settings/proxy/proxy";
export default {
  name: "Settings",
  props: ["user"],
  // components: { system, crawler, providers, smtp, addition, pitchers, click_monitoring, chat, device, ev, system_incidents, automation, proxy },
  components: { system, crawler, smtp, addition, pitchers, click_monitoring, chat, device, ev, system_incidents, automation, social,inboxEhanced },
  data: function () {
    return {
        showButton:true,
      chat: false,
      proxy: false,
      automation: false,
        social: false,
      colors: [
        { name: "Red", code: "red-circle-lv" },
        { name: "Blue", code: "blu-circle-lv" },
        { name: "Green", code: "grn-circle-lv" },
        { name: "Yellow", code: "ylw-circle-lv" },
        { name: "White", code: "wht-circle-lv" },
        { name: "Purple", code: "purple-circle-lv" },
      ],
      providers: [],
      item: {
        ev: {},
        devices: [],
        costPerClick:[],
        providers: [],
        product:[ ],
          recipient:[],
          expectedMonthlyClicks:0,
          blocklist:[],
        proxy: {
          didsoft: {},
          flipnode: {},
          homeip: {},
          proxyguys: {},
        },
        queueTargeting: "no",
      },
      defaultsettings: {
        providers: [],
        ev: {},
        proxy: {
          didsoft: {},
          flipnode: {},
          homeip: {},
          proxyguys: {},
        },
        exclusions: [],
        sideload_patterns: [],
        queueTargeting: "no",
      },
        clkMon: false,
    };
  },
  created: function () {
    this.load();
    
  },
  methods: {
    ...mapMutations(["setSettings"]),
      addClickCost() {
          this.save()
      },
      removeClickCost(cost) {
        console.log(cost)

      },
      addDevices(device) {
          this.item.devices.push(device);
          this.item.devices = [...this.item.devices];
      },
      removeDevices(i) {
        this.item.devices.splice(i, 1);
        this.item.devices = [...this.item.devices];
      },
    getProviders() {
      //Load the providers
      // this.$root.preloader = true;
      this.providers = [];

      axios.get(`${this.$root.serverUrl}/admin/proxies/providers`).then(
        function (resp) {
          this.$root.preloader = false;

          let providers =
            resp && resp.data && resp.data.data ? resp.data.data : [];
            console.log('providers:', providers);
          for (let i = 0; i < providers.length; i++) {
            let prov = { name: providers[i].provider, color: "" , active: providers[i].active};
            for (let j = 0; j < this.item.providers.length; j++) {
              if (this.item.providers[j].name === prov.name){
                prov.color = this.item.providers[j].color;
                // prov.active = this.providers[j].active ? this.item.providers[j].active : false;
              }
            }
            this.providers.push(prov);
          }
        }.bind(this)
      );
    },
    load: function (year) {
      //Load the settings

      var request = { apikey: this.$root.apikey, year: year || moment().format('YYYY') };
      // this.$root.preloader = true;

      axios
        .get(`${this.$root.serverUrl}/admin/settings`, { params: request })
        .then(
          function (resp) {
            this.$root.preloader = false;
            this.item =
              resp && resp.data && resp.data.data
                ? Object.assign(this.defaultsettings, resp.data.data.settings)
                : this.item;

              // if (!this.item.inboxEnhanced) this.item.inboxEnhanced = {exclusionList: [], deleteList: []}

              // if (!this.item.inboxEnhancedChangeHistory) this.item.inboxEnhancedChangeHistory = []
              if (!this.item.inboxEnhancedProxies) this.item.inboxEnhancedProxies = []
              if (!this.item.inboxEnhanced) this.item.inboxEnhanced = {exclusionList: [], deleteList: [], forwardList: []}
              if (!this.item.inboxOriginalItem) this.item.inboxOriginalItem = {exclusionList: [], deleteList: [], forwardList: []}
              if (!this.item.inboxEnhancedChangeHistory) this.item.inboxEnhancedChangeHistory = []
              this.item.exclusions =  this.item.exclusions.split(',')
            this.item.sideload_patterns =  this.item.sideload_patterns.split(',')
            this.$store.state.settings.forecastedData = this.item.forecastedData;
            console.log( this.item.costPerClick,'costPerClick')
            this.item.costPerClick == undefined ?  this.item.costPerClick  = [] :this.item.costPerClick
              this.item.product == undefined ?  this.item.product  = [] :this.item.product

              this.item.recipient == undefined ?  this.item.recipient = []: this.item.recipient,
                  this.item.expectedMonthlyClicks == undefined ? this.item.expectedMonthlyClicks == []: this.item.expectedMonthlyClicks

              this.item.blocklist == undefined ? this.item.blocklist == []: this.item.blocklist
              console.log( this.item.costPerClick,'costPerClick')
            this.$forceUpdate();
          }.bind(this)
        );
        this.getProviders();
    },
    tabbed() {
        let idx = this.$refs.clk_mon_tabs?.currentTab
        let tabTitle = this.$refs.clk_mon_tabs.tabs[idx].title
        if(tabTitle == 'Inbox Enhanced') {
           this.showButton = false
        }else{
            this.showButton = true
        }

        tabTitle === 'Click Monitoring' ? this.clkMon = true : this.clkMon = false;
        this.chat = this.$refs.clk_mon_tabs?.currentTab == 6;
        // this.proxy = this.$refs.clk_mon_tabs?.currentTab == 4;
        this.automation = this.$refs.clk_mon_tabs?.currentTab == 10;
        // this.$forceUpdate()
    },
    saveClkMon() {
        // alert('FORCAST SAVED')
      //Handle saving the changes
        this.item.exclusions = this.item.exclusions.join(',')
        this.item.sideload_patterns =  this.item.sideload_patterns.join(',')
      var request = { apikey: this.$root.apikey, args: this.item };
      // this.$root.preloader = true;

      axios({
        url: `${this.$root.serverUrl}/admin/settings`,
        data: request,
        responseType: "json",
        method: "POST",
      })
        .then(
          function (resp) {
            //Process the results
            this.$root.preloader = false;
            if (resp && resp.data) {
              //Saved successfully, now update the parent
              this.setSettings();
              swal({
                title: "Notice",
                text: "Your changes were saved successfully",
                icon: "success",
              }).then(
                function () {
                  //this.item = Object.assign(this.defaultsettings, resp.data.data);
                  this.load();
                }.bind(this)
              );
            } else {
              //Handle errors
              this.errormsg = resp && resp.error ? resp.error : false;
              this.$root.preloader = false;
            }
          }.bind(this)
        )
        .catch(
          function (err) {
            this.$root.preloader = false;
            this.errormsg = err.message || "An unexpected error occured";
            swal({ title: "Error", text: this.errormsg, icon: "error" });
          }.bind(this)
        );
    
    },

    save: function () {
        console.log(this.item.exclusions)
      //Handle saving the changes
      this.item.exclusions =  this.item.exclusions.join(',')
      this.item.sideload_patterns =  this.item.sideload_patterns.join(',')
      var request = { apikey: this.$root.apikey, args: this.item };
      // this.$root.preloader = true;

      axios({
        url: `${this.$root.serverUrl}/admin/settings`,
        data: request,
        responseType: "json",
        method: "POST",
      })
        .then(
          function (resp) {
            //Process the results
            this.$root.preloader = false;
            if (resp && resp.data) {
              //Saved successfully, now update the parent
              this.setSettings();
              swal({
                title: "Notice",
                text: "Your changes were saved successfully",
                icon: "success",
              }).then(
                function () {
                  //this.item = Object.assign(this.defaultsettings, resp.data.data);
                  this.load();
                    this.$forceUpdate();
                }.bind(this)
              );
            } else {
              //Handle errors
              this.errormsg = resp && resp.error ? resp.error : false;
              this.$root.preloader = false;
            }
          }.bind(this)
        )
        .catch(
          function (err) {
            this.$root.preloader = false;
            this.errormsg = err.message || "An unexpected error occured";
            swal({ title: "Error", text: this.errormsg, icon: "error" });
          }.bind(this)
        );
    },
  },
};
</script>

<style>
body {
  background-color: #e9e9e9;
}

.btn-primary {
  background-color: #007bff;
  border-color: #ffebeb;
}
</style>
